
window.instantsearch;
const { autocomplete, getAlgoliaResults } = window['@algolia/autocomplete-js'];
// const { autocomplete, getAlgoliaResults } = require('./minified/autocomplete-js');
const searchbox = window.instantsearch.widgets.searchBox({
  container: '#autocomplete',
  placeholder: 'Search for programs and institutions',
});

// const { createAlgoliaInsightsPlugin } = window[
//   '@algolia/autocomplete-plugin-algolia-insights'
// ];
const createAlgoliaInsightsClient = window.AlgoliaAnalytics.createInsightsClient;
const historyRouter = instantsearch.routers.history;

const { connectSearchBox } = instantsearch.connectors;
const virtualSearchBox = connectSearchBox(() => {});
const instantSearchRouter = historyRouter();

const appId = 'JBY4H547QZ';
const apiKey = '133c145ebb78c84a04aefb61c32dba1d';
// /* global algoliasearch instantsearch */
const searchClient = algoliasearch( appId, apiKey );

// insightsClient('init', { appId, apiKey, useCookie: true });
function requestFn(url, data) {
  const searializeData = JSON.stringify(data);
  const { protocol, host, path } = require("url").parse(url);
  const options = {
    protocol,
    host,
    path,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": searializeData.length,
    }
  };

  const { request: nodeRequest } = url.indexOf('https://') === 0 ? require("https") : require("http");
  const req = nodeRequest(options);

  req.on("error", error => {
    console.error(error);
  });
  req.write(searializeData);
  req.end();
}
const algoliaInsightsPlugin = createAlgoliaInsightsClient({ requestFn });
const INSTANT_SEARCH_INDEX_NAME = 'goml_SCOUT';

const instSearch = instantsearch({
  searchClient,
  indexName: INSTANT_SEARCH_INDEX_NAME,
  
  routing: {
    router: instantSearchRouter,
    stateMapping: instantsearch.stateMappings.simple(),
  },
});

instSearch.addWidgets([
  virtualSearchBox({}), 
  // hitscontainer
]);

instSearch.start();

function setInstantSearchUiState(indexUiState){
  // console.log(indexUiState);
  instSearch.setUiState(uiState => ({
    ...uiState,
    INSTANT_SEARCH_INDEX_NAME: {
      ...uiState.indexName,
      query: indexUiState,
      page: 1,
      ...indexUiState,      
    },
  }));
}

function getInstantSearchUiState(){
  const uiState = instantSearchRouter.read();

  return ( uiState && uiState.INSTANT_SEARCH_INDEX_NAME ) || {};
}

const searchPageState = getInstantSearchUiState();

let skipInstantSearchUiStateUpdate = false;


const { setQuery } = autocomplete({
  openOnFocus: false,
  container: '#autocomplete',
  detachedMediaQuery: "none",
  placeholder: 'Search Programs & Degrees',
  initialState: {
    query: searchPageState.query || '',
  },
  onSubmit( { state }) {
    window.location = "/degree-program?query=" + state.query; 
  },
  onReset(){
    setInstantSearchUiState({ query: ''});
  },
  onStateChange({ prevState, state }){
    if( !skipInstantSearchUiStateUpdate && prevState.query !== state.query ){
      setInstantSearchUiState({ query: state.query });
    }
    skipInstantSearchUiStateUpdate = false;
  },
  getSources({ query }){
    return [
      {
        // sourceId: 'goml_SCOUT',
        sourceId: 'goml_SCOUT_DEV',
        getItemUrl({ item }) {
          var programLink = `/degree-program/${item['Institution']
                          .replace(/\s+/g, '-')
                          .toLowerCase()}/${item['slug']}`;
          return programLink;
        },
        getItems({ query }) {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                // indexName: 'goml_SCOUT',
                indexName: 'goml_SCOUT_DEV',
                query,
                params: {
                  clickAnalytics: true,
                },
              },
            ],
          });
        },
        initialState: {
          query: new URL(window.location).searchParams.get('search'),
        },
        
        templates: {
          noResults() {
            return 'No results were found given the criteria provided.';
          },
          
          item({ item, components, state, html }) {
            return html`<div class="aa-ItemWrapper hit-container">  
              
                <div class="aa-ItemContent">
                <div class="aa-ItemIcon--picture">
                  <img src="/assets/img/institutions/icons/${item['Institution'].replace(/\s+/g, '-').toLowerCase()}.svg" 
                        alt="${item['Institution']} icon" />
                </div>
                <div class="aa-ItemContentBody">
                  <div class="aa-ItemContentTitle">
                    <h4>${components.Highlight({ hit: item, attribute: 'Program Name'})}</h4>
                  </div>
                  <div class="aa-ItemContentDescription">                      
                    <h5>${components.Highlight({ hit: item, attribute: "Institution" })}</h5>                      
                  </div>                    
                </div>
              </div>  
              <div class="aa-ItemActions">
                <button type="button" title="Select"
                        onClick="${(eventObject) => { 
                          eventObject.stopPropagation(); 
                          setQuery( item.query );
                          refresh();
                        }}"
                        class="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                >
                  <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                    <path
                      d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z"
                    />
                  </svg>
                </button>
              </div>               
            </div>
            `;
          },
        },
        onSelect: function(eventObject){
          window.location.assign(eventObject.itemUrl);
        }
      }
    ];
  }, // complete getSources()
  navigator: {
    navigate({ itemUrl }){
      window.location.assign(itemUrl);
    },
    navigateNewTab({ itemUrl }) {
      const windowReference = window.open(itemUrl, '_blank', 'noopener');

      if (windowReference) {
        windowReference.focus();
      }
    },
    navigateNewWindow({ itemUrl }){
      window.open(itemUrl, '_blank', 'noopener');
    },
  },
}); 

window.addEventListener('popstate', () => {
  skipInstantSearchUiStateUpdate = true;
  setQuery(search.helper?.state.query || '');
});
