$(document).ready(function () {
  $('.list-icons__item').on({
    mouseenter: function () {
      //lower opacity on logos in slick slider
      $('.list-icons__item').not($(this)).addClass('active-item');
    },
    mouseleave: function () {
      //restores opacity
      $('.list-icons__item').not($(this)).removeClass('active-item');
    },
  });
});

//Accessibility fix for slick slider
$('.list-icons__list').on(
  'afterChange',
  function (event, slick, currentSlide) {
    setTimeout(function () {
      $('.slide-name .slick-slide.slick-current').attr('aria-hidden', false);
    }, 100);
  }
);
